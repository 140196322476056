.mining-pool{
    width: 100%;
    padding: 64px 0;
    background: #f9f9f9;
    min-height: 100vh;
    .container{
        padding: 20px 35px;
        @media (max-width: 767px) {
            padding: 20px 30px;
        }
    }
    &>div{
        max-width: 1200px;
        margin: 0 auto;
    }
    .join{
        background: #40BA5B;
        border-radius: 0px 0px 6px 6px;
        text-align: center;
        color: #F5FFB5;
        font-size: 14px;
        height: 36px;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 5px 0;
        line-height: 1.1;
        svg{
            margin-right: 5px;
        }
        span{
            font-weight: 700;
            color: #fff;
        }
    }
    
    .ant-tabs{
        &-ink-bar{
            display: none;
        }
        &-tab{
            font-size: 16px;
            color: #000;
            &-active{
                font-size: 16px;
                font-weight: bold;
                color: #000;
                .ant-tabs-tab-btn{
                    color: #000;
                }
            }
        }
        &-top{
            &> .ant-tabs-nav::before{
                border-bottom: none;
            }
        }
        .content{
            @media (max-width: 767px) {
                padding: 10px;
                position: relative;
                &::before{
                    position: absolute;
                    content: '';
                    width: 100%;
                    left: 0;
                    top: 33.3%;
                    border-bottom: 1px solid #DEDEDE;
                }
            }
        }
    }
    .content{
        box-shadow: 0px 4px 14px rgba(0, 0, 0, 0.1);
        border-radius: 6px;
        background-color: #fff;
        cursor: pointer;
        &:not(:first-of-type){
            margin-top: 20px;
        }
    }
    .descriptions{
        display: grid;
        grid-template-columns: 60px 1fr 0.8fr 1.2fr 1fr 1fr 0.6fr;
        grid-gap: 20px;
        padding: 16px 26px;
        @media (max-width: 767px) {
            grid-template-columns: 45px 0.6fr 1fr;
            grid-template-rows: repeat(3, 1fr);
            grid-gap: 10px;
        }
        &>div{
            img{
                width: 45px;
            }
            @media (max-width: 767px) {
                &:last-of-type{
                    grid-column-start: 3;
                    grid-column-end: 4;
                    grid-row-start: 1;
                    grid-row-end: 2;
                    text-align: right;
                    div{
                        &:last-of-type{
                            justify-content: flex-end;
                        }
                    }
                }
                &:nth-of-type(3){
                    grid-column-start: 1;
                    grid-column-end: 3;
                    grid-row-start: 2;
                    grid-row-end: 3;
                }
                &:nth-of-type(5){
                    grid-column-start: 1;
                    grid-column-end: 3;
                    grid-row-start: 3;
                    grid-row-end: 4;
                }
            }
            &:nth-of-type(3),&:nth-of-type(4){
                img{
                    width: 24px;
                    height: 24px;
                    margin-right: 10px;
                }
            }
            &>div{
                &:first-of-type{
                   color: #9A9A9A;
                   height: 20px;
                }
                &:last-of-type{
                    color: #000;
                    font-size: 18px;
                    height: 32px;
                    line-height: 1;
                    display: flex;
                    align-items: center;
                }
            }
            .productName{
                @media (max-width: 767px) {
                    font-size: 14px!important;
                }
            }
            &.closed{
                &>div{
                    &:last-of-type{
                        color: #9A9A9A;
                    }
                }
            }
            .ant-badge{
                &-status-dot{
                    height: 12px;
                    width: 12px;
                }
            }
            .ant-progress{
                position: relative;
                &-inner{
                    background: #F4F4F4;
                    border: 1px solid rgba(0, 0, 0, 0.1);
                    border-radius: 60px;
                    height: 20px;
                }
                &-outer{
                    padding-right: 0;
                }
                &-bg{
                    background: linear-gradient(180deg, #40BA5B 0%, #9FE47E 100%);
                    border-radius: 60px;
                    height: 18px!important;
                }
                &-text{
                    position: absolute;
                    left: 50%;
                    transform: translateX(-50%);
                    color:rgba(0, 0, 0, 0.5);
                    font-size: 12px;
                    font-weight: bold;
                    line-height: 24px;
                    margin-left: 0;
                }
                &-status-success{
                    .ant-progress-bg{
                        background: linear-gradient(0deg, #FFCC68 0%, #FF8E26 100%);
                    }
                }
            }
        }
    }
    .nodata{
        background: #F4F4F4;
        border-radius: 6px;
        width: 100%;
        height: 300px;
        justify-content: center;
        display: flex;
        flex-direction: column;
        align-items: center;
        color: #9A9A9A;
    }
}
@primary-color: #000;@border-radius-base: 20px;