.home{
    background: rgba(0, 0, 0, 1);
    color: #fff;
    font-family: 'Poppins';
    h1{
        font-weight: 600;
    }
    .FloatDiv_component{
        width: auto;
        height: auto;
        position: relative;
        .FloatDiv_component_aniBox{
          opacity: 0;
          transform: translate(0, 50px);
        }
        .FloatDiv_component_aniClass{
          opacity: 1;
          transform: translate(0, 0)!important;
        }
      }
      
    .content{
        max-width: 1440px;
        width: 100%;
        margin: 0 auto;
        // padding-left: 100px;
        // padding: 0 35px;
        @media (max-width: 767px) {
            padding: 0 30px;
        }
    }
    .first_content{
        max-width: 1440px;
        
        height:836px;
        // padding: 0px 65px;
        background: url(../../assets/img/hometop.png) no-repeat;
        background-size: 97%, cover;
        background-position:   right center;
        display: flex;
        justify-content: center;
        margin: auto;
        flex-direction: column;
        @media (max-width: 1500px) {
            background-size: 86%, cover;
        background-position:   center center;
           
                    
                }
        @media (max-width: 1400px) {
        background-size: 84%, cover;
       
                
            }
        @media (max-width: 992px) {
            background-size: 70%, cover;
        background-position:  center center;
        // background-position: 100% 100%;
        }
        @media (max-width: 767px) {
            height: 900px;
            background: url(../../assets/img/phoneBack.png) no-repeat;
           
            background-size: 100%, cover;
            background-position: center bottom;
            
        }
        // .content{
        //     padding: 100px 35px;
            
        //     z-index: 10;
        //     @media (max-width: 767px) {
        //         padding: 50px 30px;
        //     }
        // }
       .first{
          
            display: grid;
            grid-template-columns: 1fr 1.4fr;
            padding-bottom: 50px;
            padding-left: 40px;
            @media (max-width: 1400px) {
            padding-bottom: 40px;
             
            }
            @media (max-width: 767px) {
                position: absolute;
                top: 100px;
                left: 32px;
            padding-left: 0px;
                
                // grid-template-columns: 1fr;
                // padding-bottom: 300px;
            }
            h1{
                width: 730px;
                font-weight: 700;
                font-size: 90px;
                line-height: 1.2;
                font-style: normal;
                color: #FFFFFF;
              
                @media (max-width: 992px) {
                    font-size: 50px;
                    width: 250px;
                }
                @media (max-width: 767px) {
                    font-size: 50px;
                    width: 250px;
                }
            }
            p{
                width: 540px;
                font-weight: 400;
                font-size: 34px;
                line-height: 52px;
                color: #FFFFFF;
                @media (max-width: 992px) {
                    font-weight: 400;
                    font-size: 24px;
                    line-height: 38px;
                    width: 312px;
                }
                @media (max-width: 767px) {
                    font-weight: 400;
                    font-size: 24px;
                    line-height: 38px;
                    width: 312px;
                }
            }
            .luanchbtn{
                width: 200px;
                height: 50px;
                background: url(../../assets/img/luanchBtn.png) no-repeat;
           
                background-size: 100%, cover;
                font-style: normal;
                font-weight: 700;
                line-height: 50px;
                text-align: center;
                text-transform: uppercase;
                cursor: pointer;
                .luanchlink{
                color: #FFFFFF;
                font-size: 16px;
                    font-weight: 400;
                    text-decoration:none
                    }
            }
            .images{
                position: relative;
            }
            .image1{
                width: 60px;
                position: absolute;
                top: -80px;
                right: 0;
            }
            .ant-btn-default{
                border: 1px solid #000;
                color: #000;
                padding: 0 36px;
                a:hover{
                    text-decoration: none;
                    
                }
            }
       }
       .background {
            height: 100%;
            overflow: hidden;
            position: absolute;
            width: 100%;
            top: 0;
            left: 0;
            img {
                animation-duration: 5s;
                animation-iteration-count: infinite;
                animation-name: move;
                animation-timing-function: linear;
                backface-visibility: hidden;
                position: absolute;
                &:nth-child(1) {
                    animation-delay: -4.7s;
                    animation-duration: 11.7s;
                    left: 5%;
                    top: 40%;
                    width: 296px;
                    @media (max-width: 992px) {
                        width: 200px;
                        top: 28%;
                    }
                    @media (max-width: 767px) {
                        width: 130px;
                        top: 30%;
                        left: 50%;
                    }
                }
                &:nth-child(2) {
                    animation-delay: -7.6s;
                    animation-duration: 13.5s;
                    left: 40%;
                    top: 35%;
                    width: 187px;
                    @media (max-width: 992px) {
                        width: 120px;
                        top: 25%;
                    }
                    @media (max-width: 767px) {
                        width: 80px;
                        left: 10%;
                        top: 35%;
                    }
                }
            }
            @keyframes move {
                0% { transform: translateY(0)}
                50% { transform: translateY(-30%)}
                100% {transform: translateY(0)}
            }
        }
    }
    .group_content{
        height: 1440px;
        color: #FFFFFF;
        @media (max-width: 767px) {
            height: 1200px;
        }
        .content{
            height: 100%;
            #horizontalArrows{
                position: absolute;
                top:0;
                left: 0;
                width: 100%;
                height: 100%;
                .left{
                    position: absolute;
                    width: 495px;
                    left: -25%;
                    top: 535px;
                    @media (max-width: 767px) {
                        width: 320px;
                        left: -90%;
                        top: 0;
                    }
                    &.active{
                        left: -3%;
                        transition: all 1s ease-out;
                        @media (max-width: 767px) {
                            left: -10%;
                        }
                    }
                }                
                img{
                    width: 100%;    
                }
                .right{
                    position: absolute;
                    width: 472px;
                    right: -25%;
                    bottom: 560px;
                    z-index: 2;
                    @media (max-width: 767px) {
                        width: 320px;
                        right: -80%;
                        bottom: 100px;
                    }
                    &.active{
                        right: 3%;
                        transition: all 1s ease-out;
                    }
                }
            }

            .groups{
                width: 100%;
                position: relative;
                height: 100%;
                overflow: hidden;
                @media (max-width: 767px) {
                    position: relative;
                    display: flex;
                    flex-direction: column;
                    padding-top: 50px;                    
                }
                .groupBg{
                    width: 820px;
                    height: 1030px;
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    margin-left: -420px;
                    margin-top: -515px;
                    // transform: translate(-50%,-50%);
                    transform:rotate(-15deg);
                    @media (max-width: 767px) {
                        display: none 
                        
                    }
                    // z-index: -1
                }
                h2{
                    font-style: normal;
                    font-weight: 700;
                    font-size: 50px;
                    line-height: 72px;
                    color: #FFFFFF;
                    @media (max-width: 1350px) {
                        font-size: 38px; 
                    line-height: 50px;
                    }
                    @media (max-width: 950px) {
                        margin-bottom: 0;

                        
                    }
                    @media (max-width: 767px) {
                        font-size: 30px; 
                        line-height: 50px;
                    }
                }
                p{
                    font-weight: 400;
                    font-size: 24px;
                    line-height: 42px;
                    @media (max-width: 1350px) {
                        font-size: 20px; 
                        line-height: 36px;
                        
                    }
                    @media (max-width: 900px) {
                        font-size: 18px; 
                        line-height: 1.5em;
                        
                    }
                    @media (max-width: 767px) {
                        font-size: 18px; 
                        line-height: 36px;
                        
                    }
                }
                .group1{
                    position: absolute;
                    top: 58px;
                    left: 0;
                    // width: 741px;
                    // height: 420px;
                    width: 53%;
                    // height: 50%;
                    z-index: 1;
                    filter: drop-shadow(-10px 2px 54px rgba(25, 56, 32, 0.5));                    // 
                    // @media (max-width: 1350px) {
                         
                    //     scale: 0.8

                    // }
                   
                    @media (max-width: 767px) {
                        scale: 1;
                        position: static;
                        padding: 0;
                        margin: 0;
                        width: 300px;
                        height: 200px;
                       
                    }
                    .group1_text{
                        position: absolute;
                        width: 60%;
                        top: 21%;
                        // left: 0;
                        // top: 100px;
                        // left: 144px;
                        left: 20%;
                        @media (max-width: 900px) {
                            width: 70%;
                            
                        }
                        @media (max-width: 767px) {
                            width: 100%;  
                            height: 100%;
                            position: static;
                                           
    
                        }
                    }
                    
                    img{
                        width: 100%;
                        height: 100%;
                        @media (max-width: 767px) {
                        display: none
                        }
                    }
                }
                .group2{
                    position: absolute;
                    right: 0;
                    top: 244px;
                    // width: 817px;
                    // height: 541px;
                    width: 55%;
                    z-index: 3;
                    filter: drop-shadow(-10px 2px 54px rgba(25, 56, 32, 0.5));  
                    // @media (max-width: 1350px) {
                         
                    //     scale: 0.6
                    // }
                    @media (max-width: 767px) {
                        position: static;
                        width: 332px;                        
                        height: 290px;
                        display: flex;
                        
                        flex-direction: column;
                        scale: 1                      
                        
                    }
                    .group2_text{
                        position: absolute;
                        width: 70%;
                        top: 20%;
                        left: 18%;
                        // top: 102px;
                        // left: 133px;
                        @media (max-width: 767px) {
                            width: 100%;  
                            height: 100%;
                            position: static;
                                                       
                            
                        }
                    }
                    
                    img{
                        width: 100%;
                        height: 100%;
                        @media (max-width: 767px) {
                            display: none
                            }
                    }
                }
                .group3{
                    position: absolute;
                    left: 0;
                    top: 600px;
                    width: 68%;
                    // width: 981px;
                    // height: 460px;
                    filter: drop-shadow( -4px 4px 54px #193820);                    // 
                    z-index: 2;
                  
                    // @media (max-width: 1350px) {
                        
                    //     scale: 0.7
                    // }
                    @media (max-width: 767px) {
                        position: static;
                        width: 321px;                        
                        height: 250px;
                        display: flex;
                        flex-direction: column;
                        scale: 1                        
                        
                    }
                    .group3_text{
                        position: absolute;
                        width: 50%;
                        left: 17%;
                        top: 19%;
                        @media (max-width: 767px) {
                            width: 100%;  
                            height: 100%;
                            position: static;
                                                       
                            
                        }
                        
                    }
                  
                    img{
                        width: 100%;
                        height: 100%;
                        @media (max-width: 767px) {
                            display: none
                            }
                    }

                }
                .group4{
                    position: absolute;
                    right: 23px;
                    // bottom: 0;
                    top: 960px;
                    width: 53%;
                    // width: 758px;
                    // height: 460px;
                    // filter: drop-shadow( -4px 4px 54px #193820);                    // 
                    // @media (max-width: 1350px) {
                         
                    //     scale: 0.8
                    // }
                    @media (max-width: 767px) {
                        position: static;
                        width: 332px;                        
                        height:350px;
                        display: flex;
                        flex-direction: column;
                        scale: 1                        
                        
                    }
                    .group4_text{
                        position: absolute;
                        width: 70%;
                        top: 19%;
                        left: 17%;
                        @media (max-width: 1370px) {
                            width: 85%;
                            top: 13%;
                            left: 14%;                  
                            
                        }
                        @media (max-width: 1040px) {
                            width: 85%;
                            top: 13%;
                            left: 14%;                  
                            
                        }
                        @media (max-width: 855px) {
                            width: 85%;
                            top: 10%;
                            left: 10%;                  
                            
                        }
                        @media (max-width: 767px) {
                            width: 100%;  
                            height: 100%;
                            position: static;
                                                       
                            
                        }
                    }
                    
                    img{
                        width: 100%;
                        height: 100%;
                        @media (max-width: 767px) {
                            display: none
                            }
                    }
                }
                .group5{
                    position: absolute;
                    width: 495px;
                    left: 0;
                    top: 500px;
                    @media (max-width: 767px) {
                    // position: absolute;
                    // width: 320px;
                    // left: 0;
                    // top:-1200px;
                    display: none
                    }
                    img{
                    width: 100%;
                        
                    }
                }
                .group6{
                    position: absolute;
                    width: 472px;
                    right: 46px;
                    top: 860px;
                    z-index: 2;
                    @media (max-width: 767px) {
                        position: absolute;
                        width: 320px;
                        right: 0;
                        top:-20px
                        }
                    img{
                    width: 100%;
                        
                    }
                }
                .group7{
                    display: none;
                    @media (max-width: 767px) {
                    display: block;
                        
                        position: absolute;
                        width: 320px;
                        right: 0;
                        top:0;
                        img{
                            width: 100%;
                                
                            }
                        }
                }
        }
            
        }
    }
    .three_content{
        .threetop{
            width: 100%;
            height: 64px;
            margin-top: 50px;
            margin-bottom: 50px;
            @media (max-width: 767px) {
            margin-top: 0px;
            margin-bottom: 0;
               height: 20px;
                
              }
            .threetop_img{
                width: 422px;
                height: 64px;
                @media (max-width: 767px) {
                   display: none;
                   
                 }
            }
        }
        .three_data{
            display: flex;
            align-items: flex-end;
            justify-content: space-between;
            padding: 0 69px;
            height: 450px;
            @media (max-width: 767px) {
                flex-direction: column;
                align-items: center;
                justify-content: space-between;
                padding: 0;
                height: 100%;
            }
            h2{
                font-style: normal;
                font-weight: 700;
                font-size: 32px;
                line-height: 65px;
                color: #FFFFFF;
                text-align: center;
                margin-top: 133px;
                @media (max-width: 1300px) {
                    font-size: 30px;
                    margin-top: 100px;
                    }
            }
            p{
                font-style: normal;
                font-weight: 400;
                font-size: 24px;
                line-height: 42px;
                color: #FFFFFF;
                text-align: center;
                padding: 0 67px;
                margin-top: 8px;
                @media (max-width: 1300px) {
                font-size: 18px;
                line-height: 32px;
                padding: 0 32px;
                }
            }
            .data_details1{
                position: relative;
                width: 416px;
                height: 399px;
                border: 1px solid transparent;
                border-radius: 10px;
                background-clip: padding-box, border-box;
                background-origin: padding-box, border-box;
                background-image: linear-gradient(to bottom, rgba(8, 12, 9, 1), rgba(8, 12, 9, 1)), linear-gradient(180deg, rgba(64, 186, 91, 1),rgba(0, 0, 0, 0));
                transition: all 0.5s ease;
                cursor: pointer;
                @media (max-width: 767px) {
                   width: 300px;
                   height: 313px;
                   margin-bottom: 44px;
                    
                }
                .threeicon{
                    width: 136px;
                    height: 136px;
                    position: absolute;
                    top: -28px;
                    left: 50%;
                    margin-left: -68px
                }
                .LaunchBtn{
                    position: absolute;
                    bottom: 20px;
                    left: 50%;
                    transform: translateX(-50%);
                    width: 376px;
                    height: 60px;
                    background: #1F2120;
                    border-radius: 10px;
                    font-weight: 400;
                    font-size: 24px;
                    line-height: 60px;
                    text-align: center;
                    color: #40BA5B;
                    margin: 0;
                    padding: 0;
                    display: none;
                    text-decoration: none;
                    @media (max-width: 1300px) {
                        width: 200px;
                        font-size: 18px;

                        }
                }
            }
            .data_details1:hover{
                height: 450px;
                box-shadow: 0px 10px 24px rgba(46, 255, 155, 0.2);
                .LaunchBtn{
                    display: block
                }
            }
            .data_details2{
                position: relative;
                width: 416px;
                height: 399px;
                border: 1px solid transparent;
                border-radius: 10px;
                background-clip: padding-box, border-box;
                background-origin: padding-box, border-box;
                background-image: linear-gradient(to bottom, rgba(8, 12, 9, 1),rgba(8, 12, 9, 1)), linear-gradient(180deg, rgba(0, 0, 0, 0),rgba(53, 103, 149, 1));
                transition: all 0.5s ease;
                cursor: pointer;
                @media (max-width: 767px) {
                    width: 300px;
                    height: 313px;
                    margin-bottom: 44px;
                     
                 }
                .threeicon{
                    width: 136px;
                    height: 136px;
                    position: absolute;
                    top: -28px;
                    left: 50%;
                    margin-left: -68px
                }
                .LaunchBtn{
                    position: absolute;
                    bottom: 20px;
                    left: 50%;
                    transform: translateX(-50%);
                    width: 376px;
                    height: 60px;
                    background: #1F2120;
                    border-radius: 10px;
                    font-weight: 400;
                    font-size: 24px;
                    line-height: 60px;
                    text-align: center;
                    color: #40BA5B;
                    margin: 0;
                    padding: 0;
                    display: none;
                    text-decoration: none;
                    @media (max-width: 1300px) {
                        width: 200px;
                        font-size: 18px;

                        }
                }
            }
            .data_details2:hover{
                height: 450px;
                box-shadow: 0px 10px 24px rgba(46, 255, 155, 0.2);
                .LaunchBtn{
                    display: block
                }
            }
            .data_details3{
                position: relative;
                width: 416px;
                height: 399px;
                border: 1px solid transparent;
                border-radius: 10px;
                background-clip: padding-box, border-box;
                background-origin: padding-box, border-box;
                background-image: linear-gradient(to bottom, rgba(8, 12, 9, 1), rgba(8, 12, 9, 1)), linear-gradient(180deg, rgba(107, 66, 193, 1),rgba(0, 0, 0, 0));
                transition: all 0.5s ease;
                cursor: pointer;
                @media (max-width: 767px) {
                    width: 300px;
                    height: 313px;
                    margin-bottom: 44px;
                     
                 }
                .threeicon{
                    width: 136px;
                    height: 136px;
                    position: absolute;
                    top: -28px;
                    left: 50%;
                    margin-left: -68px
                }
                .LaunchBtn{
                    position: absolute;
                    bottom: 20px;
                    left: 50%;
                    transform: translateX(-50%);
                    width: 376px;
                    height: 60px;
                    background: #1F2120;
                    border-radius: 10px;
                    font-weight: 400;
                    font-size: 24px;
                    line-height: 60px;
                    text-align: center;
                    color: #40BA5B;
                    margin: 0;
                    padding: 0;
                    display: none;
                    text-decoration: none;
                    @media (max-width: 1300px) {
                        width: 200px;
                        font-size: 18px;

                        }
                }
            }
            .data_details3:hover{
                height: 450px;
                box-shadow: 0px 10px 24px rgba(46, 255, 155, 0.2);
                .LaunchBtn{
                    display: block
                }
            }
        }
        .threebtm{
            width: 100%;
            height: 50px;
            margin-top: 60px;
            text-align: right;
            @media (max-width: 767px) {
                margin: 0;
                height: 0;
              }
            .threebtm_img{
                width: 950px;
                height: 50px;
                @media (max-width: 767px) {
                   display: none;
       
                 }
            }
        }
    }
    .storage_content{
        position: relative;
        width: 100%;
        max-width: 1550px;
        margin: 90px auto;
        height: 760px;
        padding-top: 190px;
        background: url(../../assets/img/storagebg.png) no-repeat;
           
        background-size: 100%, cover;
        background-position: center center;
        .autocenter{
            @media (max-width: 767px) {
                position: absolute;
                left: 50%;
                top: 50%;
                transform: translate(-50%,-50%)
                
              }
        }
        @media (max-width: 767px) {
            width: 100%;
            height: 577px;
            padding: 0;
            margin:  0 auto;
            background: url(../../assets/img/storagemask.png) no-repeat;
           
            background-size: 100%, cover;
            background-position: center center;
            
          }
        h1{
            
            width: 100%;
            text-align: center;
            font-style: normal;
            font-weight: 700;
            font-size: 60px;
            line-height: 65px;
            color: #FFFFFF;
            @media (max-width: 767px) {
                font-size: 40px;
              }
        }
        p{
            font-weight: 400;
                font-size: 30px;
                line-height: 52px;
                width: 768px;
                height: 104px;
                text-align: center;
                margin: 0 auto;
                color: #FFFFFF;
                @media (max-width: 767px) {
                    width: 258px;
                    height: 128px;
                    font-weight: 400;
                    font-size: 18px;
                    line-height: 32px;
                  }
        }
        .more_details{
            width: 100%;
            // height: 50px;
            text-align: center;
            margin-top: 32px;
            .morebtn{
                font-size: 16px;
                width: 200px;
                height: 50px;
                font-weight: 700;
                color: #fff;
                line-height: 50px;
                margin: 0 auto;
                background: url(../../assets/img/moredetaislbtn.png) no-repeat;
                background-size: 100%, cover;
                background-position: center center;
                cursor: pointer;
                a{
                    color: #fff;
                    text-decoration: none;
                }
            }
        }
    }
.btm_all{
    position: relative;
    .greenlong_bg{
        @media (max-width: 767px) {
            width: 91px;
            height: 1500px;
            background: linear-gradient(180deg, #40BA5B 0%, rgba(64, 186, 91, 0) 100%);
transform: rotate(-0.04deg);
            position: absolute;
            left: 0;
            top: 0;
            z-index: 2
        }
      
    }
}
.road_map{
    width: 100%;
    height: 750px;
    position: relative;
    margin-top: 150px;
  
    @media (max-width: 767px) {
       display: flex;
        flex-direction: column;
        height: 1000px;
        margin-top: 150px;
        
      }
    h2{
        font-style: normal;
        font-weight: 700;
        font-size: 32px;
        line-height: 65px;
        color: #FFFFFF;
    }
    li{
        font-style: normal;
        font-weight: 400;
        font-size: 24px;
        line-height: 45px;
        color: #FFFFFF;
        list-style: none;
        @media (max-width: 767px) {
         font-size: 18px;
        line-height: 45px;
        list-style: inherit        
        }
       
    }
  
    .greenline{
        width: 100%;
        height: 4.1%;
        position: absolute;
        top:376px;
        z-index: 1;
        @media (max-width: 1450px) {
            display: none
        }
        @media (max-width: 767px) {
            display: none
        }
        .greenline1{
        width: 50%;
        height: 100%;
        
        background: #064F16;
        position: absolute;
        left: 0
        // height: 14px;            
        }
        .greenline2{
        width: 50%;
        height: 100%;
        background:  #40BA5B;
        position: absolute;
        right: 0
           
        }
        
    }
    .content{
        // width: 1440px;
        // height: 700px;
        z-index: 2;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%,-50%);
        background-color: #000;
        @media (max-width: 767px) {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            width: 100%;
            height: 100%;
             background-color: transparent;
            
            // height: 1000px;
        }
     
       img{
           width: 100%;
           height: 100%;
           @media (max-width: 767px) {
            display: none
        }
       }
       .road_maptitle{
        position: absolute;
        top: -20px;
        left:156px;
         font-style: normal;
        font-weight: 700;
        font-size: 60px;
        line-height: 65px;
        color: #FFFFFF;
        z-index: 4;
        @media (max-width: 767px) {
            
            left: 50%;
            transform: translateX(-50%);
        font-size: 40px;
        top: -100px;
            
        }
    }
       .q1{
        width:20%;
        position: absolute;
        z-index: 3;
        top: 22%;
        right: 14%;
        @media (max-width: 1300px) {
           
             font-size: 20px;
            text-align: left;
             
            
             
         }
        @media (max-width: 767px) {
            top: 736px;
            left: 20px;
            font-size: 32px;
            width: 70px;
            height: 100px;
        }
       }
       .q1_content{
        width: 20%;
        height: 30%;
        position: absolute;
        z-index: 3;
        top: 32%;
        right: 14%;
        padding: 0;
        @media (max-width: 1300px) {
           li{
            font-size: 18px;
            line-height: 1.9em;
            
           }
            
        }
        @media (max-width: 1050px) {
           
            li{
            line-height: 1.5em;
             
            }
             
         }
        @media (max-width: 767px) {
            top: 736px;
            left: 120px;
            font-size: 18px;
            width: calc(100% - 130px);
            height: 135px;
            li{
                line-height: 45px;
                    
                }
        }
       }
       .q2{
        width:20%;
        position: absolute;
        z-index: 3;
        top: 39%;
        left:5%;
        @media (max-width: 1300px) {
           
            font-size: 20px;
            text-align: left;
            
           
            
        }
        @media (max-width: 767px) {
            top: 0;
            left: 20px;
            font-size: 32px;
            
        }
       }
       .q2_content{
        width: 30%;
        height: 30%;
        position: absolute;
        left:65px;
        z-index: 3;
        top: 56%;
        padding: 0;
        @media (max-width: 1300px) {
            li{
             font-size: 18px;
             line-height: 1.9em;
             
            }
             
         }
         @media (max-width: 1050px) {
           
            li{
            line-height: 1.5em
             
            }
             
         }
        @media (max-width: 767px) {
            top: 0;
            left: 120px;
            font-size: 18px;
            width: calc(100% - 130px);
            height: 225px;
            li{
                line-height: 45px;
                    
                }
        }
       }
       .q3{
        width: 20%;
        height: 10%;
        position: absolute;
        z-index: 3;
        top: 18%;
        left: 31%;
        @media (max-width: 1300px) {
           
            font-size: 20px;
            text-align: left;
           
            
        }
        @media (max-width: 767px) {
            top: 283px;
            left: 20px;
            font-size: 32px;
            
        }
       }
       .q3_content{
        width:23%;
        height: 30%;
        position: absolute;
        left:51%;
        transform: translateX(-50%);
        z-index: 3;
        top: 19%;
        padding: 0;
        @media (max-width: 1300px) {
            top: 20%;
            
            li{
             font-size: 18px;
            line-height: 1.9em;
            }
             
         }
         @media (max-width: 1050px) {
            top: 19%;
           
                li{
                line-height: 1.5em;
           
                 
                }
                 
             }
        @media (max-width: 767px) {
            top: 283px;
            left: 120px;
            font-size: 18px;
            width: calc(100% - 130px);
            height: 225px;
        transform: translateX(0%);
        li{
            line-height: 45px;
                
            }
        }
       }
       .q4{
        position: absolute;
        left:40%;
        top: 55%;
        z-index: 3;
        @media (max-width: 1300px) {
           
            font-size: 20px;
            text-align: left;
        }
        @media (max-width: 767px) {
            top: 572px;
            left: 20px;
            font-size: 32px;
            
        }
                   
       }
       .q4_content{
        width:23%;
        height: 10%;
        position: absolute;
        left:40%;
        z-index: 3;
        top: 65%;
        padding: 0;
        @media (max-width: 1300px) {
            li{
             font-size: 18px;
             line-height: 1.9em;
             
            }
             
         }
         @media (max-width: 1050px) {
           
            li{
            line-height: 1.5em
             
            }
             
         }
        @media (max-width: 767px) {
            top: 572px;
            left: 120px;
            font-size: 18px;
            width: calc(100% - 130px);
            height: 90px;
            li{
            line-height: 45px;
                
            }
        }
       }
    }
}

    .second_content{
        background: #F7F7F7;
        box-shadow: 0px 14px 34px rgba(0, 0, 0, 0.1);
        border-radius: 10px;
        .content{
            padding: 100px 80px;
            @media (max-width: 767px) {
                padding: 50px 30px;
            }
        }
        h1,p{
            color: #000;
        }
        h1{
            font-size: 40px;
            margin-bottom: 15px;
            line-height: 1.2;
            p{
                margin-top: 5px;
            }
        }
        p{
            font-size: 18px;
            @media (max-width: 767px) {
                font-size: 18px;
            }
        }
        img{
            width: 100%;
        }
        .hr{
            width: 60px;
            height: 4px;
            background: #fff;
            margin: 20px 0;
        }
    }
    .third_content{
        background: #fff;
        border-radius: 30px;
        margin-top: -30px;
        .content{
            padding: 100px 30px;
            .third{
                display: grid;
                grid-template-columns: 1.4fr 1fr;
                grid-gap: 100px;
                @media (max-width: 767px) {
                    grid-template-columns: 1fr;
                    grid-gap: 60px;
                }
            }
        }
        .ant-col{
            text-align: center;
            p{
                font-size: 20px;
            }
        }
        img{
            width: 100%;
        }

        h1,p,li{
            color: #000;
        }
        h1{
            font-size: 40px;
            line-height: 1;
            font-weight: 700;
            margin-bottom: 10px;
        }
        h2{
            font-weight: 700;
            font-size: 20px;
        }
        p{
            font-weight: 400;
            font-size: 18px;
        }
        ul{
            padding-left: 10px;
            list-style: ' - ';
            li{
                font-weight: 400;
                font-size: 18px;
            }
        }
    }
    .fourth_content,.fifth_content,.sixth_content{
        .content{
            // padding: 100px 30px;
            display: grid;
            grid-template-columns: 1fr 1fr;
            align-items: center;
            grid-gap: 100px;
            height: 560px;
            overflow: hidden;
            @media (max-width: 767px) {
                grid-template-columns: 1fr;
                grid-gap: 20px;
                height: auto;
            }
        }
        h1{
            font-size: 40px;
            line-height: 1.2;
            font-weight: 700;
            margin-bottom: 10px;
        }
        p{
            font-weight: 400;
            font-size: 18px;
        }
        img{
            width: 100%;
        }
    }
    .fourth_content{
        background: #15832D;
        h1,p{
            color: #fff;
        }
    }
    .fifth_content{
        background: #2D2D2D;
        h2{
            font-weight: 600;
            font-size: 30px;
        }
        h1,p,h2{
            color: #fff;
        }
        img{
            transform: scale(1.3);
            @media (max-width: 767px) {
                transform: scale(1.2);
            }
        }
    }
    .sixth_content{
        background: #fff;
        h1,p{
            color: #000;
        }
    }
    .roadmap{
        position: relative;
        h1{
            margin-bottom: 40px;
        }
        &::before{
            position: absolute;
            content: '';
            top: 295px;
            width: calc(100% - 50px);
            border-top: 3px dashed #000;
            z-index: 10;
            @media (max-width: 767px) {
                display: none;
            }
        }
        &::after{
            position: absolute;
            content: '';
            right: 40px;
            top: 286px;
            width: 10px;
            border-left: 15px solid #000;
            border-top: 10px solid transparent;
            border-bottom: 10px solid transparent;
            @media (max-width: 767px) {
                display: none;
            }
        }
        .content{
            padding: 120px 35px;
            position: relative;
            @media (max-width: 767px) {
                padding: 50px 30px;
            }
        }
        .items{
            display: grid;
            grid-template-columns: repeat(4, 1fr);
            grid-gap: 20px;
            @media (max-width: 767px) {
                grid-template-columns: repeat(1, 1fr);
            }
            &>div{
                border: 2px solid #000;
                border-radius: 10px;
                padding: 20px;
                position: relative;
                &:nth-of-type(1){
                    background: linear-gradient(166.02deg, #FFFFFF 33.61%, #F9FFFA 57.72%, #B1FFC2 98.89%);
                }
                &:nth-of-type(2){
                    background: linear-gradient(195.06deg, #FFFFFF 26.9%, #F9FFFA 63.52%, #B1FFC2 98.4%);
                }
                &:nth-of-type(3){
                    background: radial-gradient(119.64% 108.53% at 42.43% 3.46%, #FFFFFF 41.63%, #F9FFFA 63.54%, #B1FFC2 90.1%, #AFFFC1 96.35%, #C3FCD0 100%);
                }
                &:nth-of-type(4){
                    background: radial-gradient(236.99% 119.51% at 179.11% -32.85%, #FFFFFF 57.29%, #F9FFFA 84.37%, #B1FFC2 100%);
                }
                &::before{
                    position: absolute;
                    top: 79px;
                    left: 20px;
                    content: '';
                    width: 23px;
                    height: 23px;
                    border: 4px solid #2D2D2D;
                    background: #fff;
                    border-radius: 50%;
                    z-index: 9;
                    @media (max-width: 767px) {
                        display: none;
                    }
                }
                &::after{
                    position: absolute;
                    top: 89px;
                    left: 0;
                    content: '';
                    width: 20px;
                    height: 3px;
                    background: #fff;
                    z-index: 11;
                    @media (max-width: 767px) {
                        display: none;
                    }
                }
                h3{
                    font-weight: 700;
                    font-size: 30px;
                    margin-bottom: 50px;
                }
                ul{
                    padding-left: 18px;
                    li{
                        font-weight: 400;
                        font-size: 20px;
                        margin-bottom: 20px;
                    }
                }
            }
        }
        .background {
            height: 100%;
            overflow: hidden;
            position: absolute;
            width: 100%;
            top: 0;
            left: 0;
            img {
                animation-duration: 5s;
                animation-iteration-count: infinite;
                animation-name: move;
                animation-timing-function: linear;
                backface-visibility: hidden;
                position: absolute;
                &:nth-child(1) {
                    animation-delay: -4.7s;
                    animation-duration: 11.7s;
                    left: 190px;
                    top: 12%;
                    width: 50px;
                    @media (max-width: 767px) {
                        width: 50px;
                        top: 2%;
                        left: 180px;
                    }
                }
                &:nth-child(2) {
                    animation-delay: -7.6s;
                    animation-duration: 13.5s;
                    right: 18%;
                    bottom: 0;
                    width: 170px;
                    z-index: -1;
                    @media (max-width: 767px) {
                        width: 100px;
                        right: 5%;
                        bottom: 0;
                    }
                }
            }
            @keyframes move {
                0% { transform: translateY(0)}
                50% { transform: translateY(-30%)}
                100% {transform: translateY(0)}
            }
        }
    }
    .seventh_content{
        background:  #080A09;
        position: relative;
        @media (max-width: 767px) {
        background:  #000;
           
        }
        // top: 100px;
        h1{
            font-weight: 700;
            font-size: 60px;
            line-height: 64px;
            color: #fff;
           padding-left: 200px;
            @media (max-width: 767px) {
                font-size: 40px;
               text-align: center;
               padding: 0
            }
        }
        .content{
            position: relative;
            z-index: 10;
            padding: 100px 35px;
            background: url(../../assets/img/groupBg.png) no-repeat;
            background-size: 80%, cover;
            background-position: left center; 
            overflow: hidden;
            @media (max-width: 767px) {
                padding-top: 0;
            background-size: 0;
                }
            // .groupBg{
            //         width: 850px;
            //         height: 1040px;
            //         position: absolute;
                
            //         // top: 50%;
            //         left: 50%;
            //         margin-left: -420px;
            //         margin-top: -850px;
            //         // transform: translate(-50%,-50%);
            //         transform:rotate(-15deg);
            //         @media (max-width: 767px) {
            //             display: none 
                        
            //         }
            // }
            ul{
                list-style: none;
                padding-left: 0;
            }
            .ant-collapse{
                border: none;
                background: none;
                max-width: 1000px;
            margin: 0 auto;
                
                .ant-collapse-header{
                    padding: 34px;
                    background: #141715;
                    font-weight: 700;
                    font-size: 18px;
                    color: #FFFFFF;
                    border: 2px solid #141715;
                    position: relative;
                    z-index: 2;
                    border-radius: 10px;
                    box-shadow: 0px 4px 14px rgba(0, 0, 0, 0.4);
                    @media (max-width: 767px) {
                        padding: 24px;
                    }
                    .ant-collapse-arrow{
                        right: 30px;
                    }
                }
                & > .ant-collapse-item{
                    border: none;
                    box-shadow: 0px 4px 14px rgba(0, 0, 0, 0.1);
                    border-radius: 10px;
                    margin-bottom: 16px;
                    overflow: hidden;
                    &-active{
                        border: 1px solid #3F5140;
                        .ant-collapse-header{
                            border: 2px solid #00B828;
                        }
                    }
                }
                .ant-collapse-content{
                    background:  #000000;;
                    padding: 52px 40px;
                    margin-top: -10px;
                    position: relative;
                    z-index: 1;
                    color: #fff;
                    font-weight: 400;
                    font-size: 16px;

                    & > .ant-collapse-content-box{
                        padding: 0;
                    }
                    p,li{
                        font-size: 16px;
                    }
                }
            }
        }
        #verticalArrows{
            position: absolute;
            top: 0;
            left: 0;
            height: 100%;
            width: 100%;
            @media (max-width: 767px) {
                display: none;   
            }
            &.active{
                img{
                    transition: all 1s ease-out;
                    &:first-of-type{
                        top: 0;
                    }
                    &:last-of-type{
                        bottom: -10%;
                    }
                }
            }
            img{
                position: absolute;
                &:first-of-type{
                    left: 100px;
                    top: -472px;
                    width: 16px;
                    height: 472px;
                }
                &:last-of-type{
                    right: 100px;
                    bottom: -472px;
                    width: 16px;
                    height: 472px;
                }
            }
        }
    }
    .footer{
        .content{
            height: 200px;
            padding: 0 35px;
            display: flex;
            align-items: center;
            justify-content: space-between;
            @media (max-width: 767px) {
                flex-direction: column;
                height: auto;
                margin-bottom: 20px;
            }
            &>div{
                &:first-of-type{
                    display: flex;
                    align-items: center;
                }
                &:last-of-type{
                   
                    font-weight: 400;
                    font-size: 18px;
                    @media (max-width: 767px) {
                        margin-top: 10px;
                    }
                    a{
                        color: #fff;
                        line-height: 33px;
                        @media (max-width: 767px) {
                            font-size: 12px;
                            line-height: 33px;
                            
                        }
                    }
                }
            }
            .footer_icon{
                display: flex;
                align-items: center;
                @media (max-width: 767px) {
                   flex-direction: column;
                   justify-content: center
                }
                
            }
        }
        .logo{
            height: 54px;
            width: auto;
            @media (max-width: 767px) {
                height: 40px;
            }
        }
        ul{
            list-style: none;
            display: inline-block;
            margin-bottom: 0;
            border-right: 1px solid #424242;
            margin-right: 45px;
            padding-right: 45px;
            height: 33px;
            @media (max-width: 767px) {
                display: flex;
                align-items: center;
                justify-content: center;
                // margin-right: 5px;
                // padding-right: 5px;
                height: 33px;
                border: none;
                padding: 0;
                margin-top: 37px;
                margin-bottom: 20px;
                margin-right: 0
            }
            li{
                display: inline-block;
                margin-right: 14px;
                @media (max-width: 767px) {
                // margin-right: 10px;
                margin: 0 5px;
                // margin-top: 13px;
                }
            }
        }
    }
    .copyright{
        background: #000;
        color: #9A9A9A;
        padding: 10px;
        text-align: center;
        a{
            color: #9A9A9A;
            margin: 0 10px;
        }
        ul{
            display: inline-block;
            list-style: none;
            margin-bottom: 0;
            @media (max-width: 767px) {
                padding-left: 0;
            }
            li{
                display: inline-block;
                font-size: 14px;
                padding: 0 10px;
                &:not(:first-of-type){
                    border-left: 1px solid #9A9A9A;
                }
            }
        }
    }
}
@primary-color: #000;@border-radius-base: 20px;