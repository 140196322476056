.radio{
    background: #F6F6F6;
    border-radius: 20px;
    padding: 5px;
    line-height: 1;
    .ant-radio-button-wrapper {
        border: none !important;
        padding: 0 5px;
        background: transparent;
        font-weight: 600;
        width: 83px;
        //width: 63px;
        height: 30px;
        line-height: 30px;
        color: #000;
        font-size: 14px;
        text-align: center;
    }
    
    .ant-radio-button-wrapper-checked {
        background: #fff;
        border-radius: 20px;
        width: 63px;
        border: none;
        color: #000 !important;
    }

    .ant-radio-button-wrapper:focus-within{
        box-shadow: none!important;
    }
    // 去掉中间的分割线
    .ant-radio-button-wrapper:not(:first-child)::before {
        content: '';
        background-color: transparent;
    }
}
.ant-layout-header{
    position: fixed;
    top: 0;
    z-index: 100;
    width: 100%;
    padding: 0;
    line-height: 64px;
    &.black{
      background: #000!important;
      .Nav a{
        color: #FFF5F5;
        &:hover{
          font-weight: bold;
        }
        &.active{
          font-weight: bold;
          color: #40BA5B;
        }
      }
    }
    &.light{
      background: #000!important;
      .Nav a{
        color: #fff;
        &:hover{
          font-weight: bold;
        }
        &.active{
          font-weight: bold;
          color: #40BA5B;
        }
      }
    }
    .Nav {
      display: flex;
      align-items: center;
      a {
        color: #fff;
        font-size: 16px;
        font-weight: 400;
        transition: 0.5s;
        padding: 10px 0;
        margin: 0 20px;
        text-decoration: none;
        line-height: 1;
        @media (max-width: 992px) {
          margin: 0 8px;
        }
        &.active{
          font-weight: bold;
          color: #40BA5B !important;
        }
      }
      &.left{
        a{
          display: flex;
          align-items: center;
          padding: 0px 10px;
          font-size: 16px;
          @media (max-width: 767px) {
          padding: 40px 10px;
            
          }
        }
      }
      &.right{
        a{
          padding: 0 10px;
        }
      }
      @media (max-width: 767px) {
        &.left{
          top: 64px;
          background: linear-gradient(180deg, #000000 0%, #15240E 100%);
          left: 0;
          grid-template-rows: repeat(4, 1fr);
        }
        &.right{
          top: 214px;
          left: 0;
          grid-template-rows: repeat(2, 1fr);
          grid-gap: 10px;
          padding-bottom: 10px;
        }
        display: flex;
        flex-direction: column;
        padding: 0;
        background: #fff;
        width: 100%;
        position: absolute;
        z-index: -1;
        margin-right: 0;
        align-items: flex-start;
        justify-items: flex-start;
        padding-left: 50px;
        padding-bottom: 44px;
        box-sizing: border-box;
        // border-bottom: 1px solid #ddd;
        a{
          color: #fff!important;
          // line-height: 50px;
          padding: 0;
          text-align: center;
          display: block;
          padding: 40px 0;
        }
        button{
          align-items: flex-end; 
        }
        .luanchbtn{
          width: 200px;
          height: 50px;
          background: url(../../assets/img/luanchBtn.png) no-repeat;
      
          background-size: 100%, cover;
          font-style: normal;
          font-weight: 700;
          line-height: 50px;
          text-align: center;
          text-transform: uppercase;
          cursor: pointer;
          display: flex;
          align-items: center;
          justify-content: center;
          a{
            padding: 0;
            text-align: center
          }
          .luanchlink{
          color: #FFFFFF;
          font-size: 16px;
          text-decoration:none
          }
      }
      }
      .ant-btn{
        margin: 10px 0;
        a{
          line-height: 1;
          color: inherit!important;
          height: auto;
          margin: 0;
        }
      }
    }
    .Nav{
      transition: all 0.5s ease;
    }
    .NavAnimation-enter {
      transform: translateY(-480px);
    }
    .NavAnimation-enter-active {
      transform: translateY(0);
    }
    .NavAnimation-exit {
      transform: translateY(-480px);
    }
    .NavAnimation-exit-active {
      transform: translateY(0);
    } 
}
.header{
  background: #000;
  width: 100%;
  max-width: 1500px;
  height: 100%;
  margin: 0 auto;
  display: grid;
  grid-template-areas: "logo nav right";
  grid-template-columns: 140px 1fr auto;
  align-items: center;
  position: relative;
  z-index: 1000;
    .Launchbtn{
      background: #000;
      border: 1px solid #fff;
      border-radius: 60px;
      width: 167px;
      height: 40px;
      margin: 0;
      position: relative;
      cursor: pointer;
      :link{
      margin: 0;
      padding: 0;
      width: 167px;
      height: 40px;
      line-height: 40px;
      text-align: center;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%,-50%);
      color:#ffffff
      }
      }
    @media (max-width: 767px) {
        width: 100%;
        grid-template-areas: "logo burger";
        grid-template-columns: 40px 1fr auto;
        display: flex;
        align-items: center;
        justify-content: space-between;
        .Nav a {
            height: 50px;
            line-height: 50px;
            padding: 0;        
        }
    }
    .ant-select{
      border-radius: 20px;
      .ant-select-selector{
        background: none;
        border: none;
      }
    }
    .Logo {
        float: left;
        width: auto;
        height: 30px;
        grid-area: logo;
    }
      
    .Burger {
      display: none;
      grid-area: burger;
      margin: 0;
      padding: 0;
      justify-self: self-start;
      font-size: 25px;
      border: none;
      background: none;
      outline: none;
      transition: 0.1s;
      @media (max-width: 767px) {
        display: inline;
      }
    }
    .wallet-icon{
      background: none;
      border: none;
      justify-content: center;
      align-items: center;
      display: none;
      @media (max-width: 767px) {
        display: flex;
        padding: 0;        
      }
    }  
}

@primary-color: #000;@border-radius-base: 20px;